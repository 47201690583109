/*fonts*/
$font-main: 'Montserrat', sans-serif;
$font-awesome: "Font Awesome 5 Pro";

/*colors*/
$color-red: #d62727;
$color-turquoise: #00c3b7;
$color-orange: #fc6303;
$color-yellow: #ff9f03;
$color-green: #009933;
$color-facebook: #3b5998;
$color-blue: #115798;


/*colors main*/
$color-main: #115798;

$colors: (
    "primary": #5867dd,
    "danger": #ff4651,
    "success": #1dc9b7,
    "warning": #ffb822,
    "info": #2786fb
);
$ease: cubic-bezier(.38,.24,.28,1.17);
