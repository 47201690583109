@import "../../theme/customise";

.login-page{
  background: url("/images/bg-login.jpg") no-repeat top center;
  @include background-size(cover);
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  min-height: 100vh;
  padding: 15px;
  &__form{
    max-width: 700px;
    width: 100%;
    font-size: 20px;
    .invalid-feedback{
      font-size: 14px;
      color: red;
      font-weight: normal;
        line-height: 2;
        + .invalid-feedback{
            display: none !important;
        }
    }
  }
  &__submit{
    padding: 0.5rem 1rem;
    background: #3b8bdd;
    border-color: #3b8bdd;
    font-size: 20px;
    line-height: 1.3;
    &:hover,
    &:focus,
    &:active{
      background: #245cd3;
      border-color: #245cd3;
    }
  }
  &__forget{
    color: #ca1d1d;
    display: inline-block;
    &:hover, &:focus{
      text-decoration: none;
    }
  }
  &__remember{
    margin: 0;
    @include flexbox();
    @include align-items(center);
    cursor: pointer;

    input[type="checkbox"]{
      display: none;
      + span{
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border: 1px solid;
        margin-right: 5px;
        &:before{
          visibility: hidden;
        }
      }
      &:checked{
        + span{
          background: #3b8bdd;
          border-color: #3b8bdd;
          color: #fff;
          font-size: 16px;
          &:before{
            visibility: visible;
          }
        }
      }
    }
  }
  &__title{
    text-align: center;
    margin-bottom: 30px;
    color: #0c3976;
    font-weight: normal;
    font-size: 35px;
  }

  .form-group{
    &__label{
      color: #373738;
      font-weight: 500;
      margin: 0;
    }
    &__field.form-control{
      width: 100%;
      border: none;
      border-bottom: 1px solid;
      color: #373738;
      background: none;
      font-size: inherit !important;
      @include border-radius(0 !important);
      height: auto;
      padding: 0;
      min-height: 0;

      &:focus{
        outline: none;
      }
      &.is-invalid{
        border-color: #f00;
      }
    }
  }
  @media (max-width: 575px) {
    &__title{
      font-size: 28px;
    }
    &__form{
      font-size: 16px;
    }
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  transition: background-color 50000s;
  font-size: inherit;
}
