@import "~bootstrap/dist/css/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
////abstracts
@import '../abstracts/variables';
@import '../abstracts/mixins';
@import "style";
@import "components";

/* scroolbar */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #bbb;
    @include border-radius(10px);
}

::-webkit-scrollbar-thumb:hover {
    background: $color-main;
}

.text-prewrap {
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
}

.font-20{
    font-size: 20px;
}

.break-word {
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

input {
    &:focus {
        outline: none;
    }
}
.invalid-feedback {
    color: red;
}

//select2
select,
input,
.form-control,
.input-group-text {
    border-style: solid;
    border-width: 1px;
    border-color: #898989;
    border-radius: 4px;
}
.custom-select2 {
    position: relative;

    select.select2-hidden-accessible {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.select2-container {
    width: 100% !important;
    display: block;

    .select2-selection--single {
        border-color: #898989;
    }
}

.select2-results {
    &__option {
        line-height: 1.3;
        @include transition(all linear .2s);
    }
}

select[class*="select2-"] {
    &.is-invalid {
        + .invalid-feedback {
            + span.select2-container {
                .select2-selection {
                    border-color: #f00;
                }
            }
        }
    }

    &.is-valid {
        + .invalid-feedback {
            + span.select2-container {
                .select2-selection {
                    border-color: #28a745;
                }
            }
        }
    }
}


//customise sidebar
.custom-sidebar {
    &.blue-sidebar.sidebar-mini .main-sidebar .sidebar-menu li ul.dropdown-menu li a,
    &.blue-sidebar.sidebar-mini .main-sidebar .sidebar-menu li ul.dropdown-menu li:hover a,
    &.blue-sidebar.sidebar-mini .main-sidebar .sidebar-user,
    &.blue-sidebar.sidebar-mini .main-sidebar .dropdown-title,
    &.blue-sidebar.sidebar-mini .main-sidebar:after,
    &.blue-sidebar .main-sidebar {
        background: $color-main;
    }

    &.theme-white a:hover {
        color: $color-main;
    }

    &.theme-white .dropdown-item:active {
        background-color: $color-main;
    }
}

.main-sidebar .sidebar-menu li:not(:last-child) {
    border-bottom: 1px solid rgba(#fff, 0.1);
}

.main-sidebar .sidebar-menu li a {
    height: auto;
    min-height: 60px;
}

.blue-sidebar .main-sidebar .sidebar-menu li.active a {
    background: rgba(#fff, 0.1);
}

.main-sidebar .sidebar-menu li.menu-header {
    font-size: 20px;
    letter-spacing: normal;
    white-space: nowrap;
    @include flexbox();
    @include align-items(center);
    padding: 50px 15px 80px;
}

.sidebar-mini .navbar {
    left: 0;
}

.sidebar-mini .main-content, .sidebar-mini .main-footer {
    padding-left: 30px;
}

.sidebar-mini .main-sidebar {
    width: 0;
    overflow: hidden;
    visibility: hidden;
}

.sidebar-mini .main-sidebar aside {
    display: none;
}

.sidebar-mini .main-sidebar:after {
    display: none;
}

.bg-blue {
    background: $color-blue;
    color: #fff;
}

.user-img-radious-style {
    @include border-radius(50%);
    width: 40px;
    height: 40px;
    @include object-fit(cover);
}

.check-right {
    input {
        float: right;
        margin-top: 5px;
        margin-left: 7px;
    }
}

//customise bootstrap
.form-control.h-auto {
    height: auto !important;
}

.pagination-transparent {
    .page-link {
        background: none;
        @include box-shadow(none);
        border: none;
        padding: 0 3px;
    }
}

.table-hover tbody tr:hover {
    background-color: rgba($color-main, 0.1) !important;
}

//date-range
.date-range {
    position: relative;

    &__to {
        position: absolute;
        left: 50%;
        @include translate(-50%, 0);
        top: 10px;
        font-size: 20px;
        line-height: 1;

        &:before {
            content: '~';
            display: inline-block;
        }
    }
}

//bootstrap-datepicker
.datepicker.dropdown-menu {
    width: 250px;
    padding: 10px;

    .table-condensed {
        width: 100%;
    }
}

.style-hide-error {
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}

.error {
    color: red;
}


.table-data-scroll {
    max-height: 80vh;
    overflow: auto;

    table {
        min-width: 700px;
    }
}

table.table {
    thead {
        th {
            background-color: #707070;
            color: #fff;
            font-weight: normal;

            a {
                color: inherit;
                font-weight: inherit;
            }
        }
    }
}

.table-scroll {
    thead {
        th {
            position: -webkit-sticky;
            position: sticky;
            top: -1px;
            z-index: 100;
        }
    }
}

.table-data-scroll {
    thead {
        th {
            padding: 0 !important;
            background-color: transparent !important;

            > div,
            > a,
            > span {
                padding: 5px 10px;
                height: 100%;
                background-color: #707070;
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
            }
            > a {
                i {
                    @include transform(translate(5px, 2px));
                }
            }
        }
    }
}

.invalid-feedback {
    order: 10;
    font-size: 14px;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

/* Style model */
.modal {
    .modal-header {
        padding: 25px;
        background-color: #115798;
        @include flexbox();
        @include align-items(center);

        .modal-title {
            color: #ffffff;
        }

        .close {
            color: #115798;
            font-size: 35px;
            width: 40px;
            height: 40px;
            padding: 0;
            opacity: 1;
            border-radius: 50%;
            background-color: #ffffff;

            span {
                display: block;
                height: 100%;
                line-height: 33px;
            }

            &:focus {
                opacity: 1;
                outline: none;
                text-shadow: none;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    @media (min-width: 1200px) {
        .modal-dialog {
            max-width: 1200px;
        }
    }
}


.button-submit {
    font-weight: normal;

    i {
        font-size: inherit;
    }
}

.btn-sticky-bottom {
    font-size: 18px;
    height: 50px;
    line-height: 42px;
    i{
        font-size: 20px;
    }
    @media (min-width: 992px) {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        bottom: 0;
        z-index: 110;
    }
    font-weight: normal !important;
}

.card-search {
    border-radius: 0;

    .card-header {
        background: #115798;
        color: white;
        padding: 15px;

        &:not(.collapsed) {
            > i.fa-chevron-down {
                &::before {
                    content: '\f077'
                }
            }
        }
    }
}

.nav-link-user{
    @include flexbox();
    @include align-items(center);
    &__name{
        @include line-clamp(1);
        max-width: 200px;
        text-overflow: ellipsis;
    }
}
.main-navbar.navbar{
    @media (max-width: 1024px){
        left: 0;
        right: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

// Switches style
.switch {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;

    // Hide input
    &__input {
        opacity: 0;
        visibility: hidden;
        width: 1px;
        height: 1px;
        background-color: transparent;
        pointer-events: none;
        position: absolute;
    }
    // Switch box
    &__box {
        padding: 3px;
        border-radius: 100px;
        position: relative;
        height: 26px;
        width: 42px;
        background-color: #e4ecfa;
        box-shadow: 0 0 10px transparent;
        transition: 0.4s all $ease;
        overflow: hidden;

        // Before for background changes
        &::before {
            content: '';
            width: 120%;
            height: 110%;
            position: absolute;
            top: -5%;
            left: -10%;
            border-radius: 100px;
            transform: translateX(-100%);
            transition: .3s ease all;
            will-change: transform, border-radius;
        }
        // Inner circle
        &-inner {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 100px;
            background-color: white;
            transform: translateX(0);
            box-shadow: 0 4px 20px rgba(white, .2);
            transition: 0.4s all $ease;
            overflow: hidden;

            &::after {
                content: '';
                height: 4px;
                width: 4px;
                border-radius: 10px;
                top: 8px;
                left: 8px;
                position: absolute;
                transform: translateX(-40px);
            }
        }
    }
    // Animation on check input
    &__input:checked ~ &__box > &__box-inner {
        animation: checkSwitch .4s $ease forwards;

        &::after {
            animation: checkSwitchAfter .4s $ease .2s forwards;
        }
    }
    // Animation on uncheck
    &__input:not(:checked) ~ &__box > &__box-inner {
        transform: translateX(-20px);
        animation: unCheckSwitch .4s $ease forwards;

        &::after {
            animation: unCheckSwitchAfter .4s $ease forwards;
        }
    }

    // Styles for all states
    @each $key, $value in $colors {
        &--#{$key} &__box-inner::after {
            background-color: rgba($value, .6);
        }
        &--#{$key} &__input:checked ~ &__box {
            box-shadow: 0 8px 12px rgba($value, .1);

            &::before {
                transform: translateX(0);
                border-radius: 20px;
            }
        }

        &--#{$key} &__box::before {
            background-color: $value;
        }
    }
    // Switches text style
    &__desc {
        margin-left: 24px;
    }

}
@each $key, $value in $colors {
    .#{$key}-color {
        color: $value;
    }
}

// Switches keyframes :)
@keyframes checkSwitch {
    0% {
        transform: translateX(0);
    }
    50% {
        width: 36px;
        transform: translateX(0);
    }
    100% {
        width: 20px;
        transform: translateX(16px);
    }
}

@keyframes checkSwitchAfter {
    from {
        transform: translateX(-40px) scale(.2);
    }
    to {
        transform: translateX(0) scale(1);
    }
}
@keyframes unCheckSwitchAfter {
    from {
        transform: translateX(0) scale(1);
    }
    to {
        transform: translateX(40px) scale(.2);
    }
}
@keyframes unCheckSwitch {
    0% {
        width: 20px;
        transform: translateX(16px);
    }
    50% {
        width: 36px;
        transform: translateX(0);
    }
    100% {
        transform: translateX(0);
    }
}
.max-px-650 {
    max-width: 650px !important;
}
@media (min-width: 992px) {
    .btn-bar-sticky-bottom {
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        z-index: 500;
        margin-left: 0!important;
        margin-right: 0!important;
    }
}
